import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Button, Figure, Col, Image } from 'react-bootstrap';

import { UqPage, UqLayoutV2, UqAppBarV2, UqNavbarV2, UqFooterV2, UqHeroV2, UqContainer, UqLegacyRequestDemoButton, UqContainerV2, UqHeroStacked, UqFigure, UqTextHighlight, UqRequestDemoBanner, UqTileRow, UqFlexRow, UqIntegrationTiles, UqFlexColumn, UqFlexGrid, UqQuickActionTile, UqTestimonialCard } from '@uq-components';
import { heroShape, figureRowShape, seoShape, tileShape, integrationShape, testimonialShape } from '../models';

import * as styles from './agentq.module.scss';

UqAgentQPage.propTypes = {
  data: PropTypes.shape({
    hero: heroShape,
    integrations: PropTypes.shape({
      content: PropTypes.arrayOf(integrationShape),
      subtitle: PropTypes.string,
      title: PropTypes.string,
    }),
    qualityCommunity: figureRowShape,
    quickActions: PropTypes.shape({
      content: PropTypes.arrayOf(tileShape),
    }),
    seo: seoShape,
    testimonials: testimonialShape,
    valueProps: tileShape,
    whyAgentq: figureRowShape,
  }),
};

export default function UqAgentQPage(props) {
  const { hero, integrations, qualityCommunity, seo, valueProps, whyAgentq } = props.data;
  const [joinWaitlist, downloadNow, getDemo] = props.data.quickActions.content;
  const testimonials = props.data.testimonials.content;

  return (
    <UqPage
      config={{
        seo: {
          appendHostUrl: false,
          description: seo.description,
          src: seo.image.url,
          title: seo.title,
        },
      }}
    >
      <UqLayoutV2 theme="dark">
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <div className={styles.heroBackground}>
          <div className={styles.heroContainer}>
            <UqContainerV2 fill>
              <UqHeroV2>
                <UqHeroStacked
                  classes={{ root: styles.hero }}
                  image={(
                    <div className={styles.heroVideoContainer}>
                      <StaticImage
                        className={styles.heroVideo}
                        alt="Why agentQ"
                        src="../images/agentq/agentq-hero.png"
                        height={540}
                      />
                    </div>
                  )}
                  renderActions={() => (
                    <div className={styles.heroActions}>
                      <UqLegacyRequestDemoButton
                        className="wide"
                        buttonProps={{ variant: 'outline-modern' }}
                      >
                        Get a Demo
                      </UqLegacyRequestDemoButton>
                    </div>
                  )}
                  subtitle={hero.subtitle}
                  title={hero.title}
                />
              </UqHeroV2>
            </UqContainerV2>
          </div>
        </div>

        <UqContainer>
          <section className={styles.whyAgentq}>
            <UqFigure>
              <UqFigure.Content>
                <UqFigure.Title className={styles.title}>
                  {whyAgentq.title}
                </UqFigure.Title>

                <blockquote className="body-2">
                  <p>{whyAgentq.description.description}</p>
                </blockquote>
              </UqFigure.Content>

              <StaticImage
                className={styles.whyAgentqVideo}
                alt="Why agentQ"
                src="../images/agentq/why-agentq.png"
              />
            </UqFigure>
          </section>
        </UqContainer>
        <section className={styles.valuesAndIntegrationsContainer}>
          <UqContainer>
            <div className={styles.valuesBlock}>
              <h2 className={styles.title}>
                <UqTextHighlight>
                  What you can **expect**
                </UqTextHighlight>
              </h2>
              <UqTileRow tiles={valueProps.content} />
            </div>
          </UqContainer>
          <UqContainer
            background="dark"
            fullWidth
            logoProps={{
              anchor: 'topLeft',
              dark: true,
            }}
          >
            <UqContainer>
              <section className={styles.quotes}>
                <UqFlexGrid classes={{ root: styles.actionsGrid }}>
                  {testimonials.map((testimonial, index) => (
                    <UqFlexColumn key={index}>
                      <UqTestimonialCard
                        avatarSrc={testimonial.author.image.url}
                        logo={testimonial.customer.logo}
                        name={testimonial.author.name}
                        quote={testimonial.quote.quote}
                        role={testimonial.author.role}
                      />
                    </UqFlexColumn>
                  ))}
                </UqFlexGrid>
              </section>
            </UqContainer>
          </UqContainer>
          <UqContainer>
            <section className={styles.actions}>
              <UqFlexGrid>
                <UqFlexColumn>
                  <UqQuickActionTile
                    classes={{ root: styles.joinWaitlistTile }}
                    dark
                    imageVariant="background"
                    renderAction={() => (
                      <UqLegacyRequestDemoButton
                        className="wide"
                        buttonProps={{ variant: 'outline-modern' }}
                      >
                        Get a Demo
                      </UqLegacyRequestDemoButton>
                    )}
                    src={joinWaitlist.image.url}
                    subtitle={joinWaitlist.desc.desc}
                    title={joinWaitlist.title}
                  />
                </UqFlexColumn>
                <UqFlexColumn>
                  <UqQuickActionTile
                    action={downloadNow.action}
                    dark
                    size="md"
                    src={downloadNow.image.url}
                    subtitle={downloadNow.desc.desc}
                    title={downloadNow.title}
                  />
                  <UqQuickActionTile
                    dark
                    action={getDemo.action}
                    size="sm"
                    title={getDemo.title}
                  />
                </UqFlexColumn>
              </UqFlexGrid>
            </section>
          </UqContainer>
          <UqContainer
            background="dark"
            fullWidth
          >
            <UqContainer>
              <div className={styles.integrations}>
                <div className={styles.integrationsBlock}>
                  <UqFlexRow
                    as={Figure}
                    classes={{
                      root: styles.figure,
                      row: styles.row,
                    }}
                  >
                    <Col xl={6} lg={6} md={12}>
                      <Figure.Caption className={styles.titleContainer}>
                        <h2 className={styles.title}>
                          <UqTextHighlight variant="dark">
                            {integrations.title}
                          </UqTextHighlight>
                        </h2>
                        <p className="body-1">
                          {integrations.subtitle}
                        </p>
                      </Figure.Caption>
                    </Col>
                    <Col xl={6} lg={6} md={12}>
                      <section className={styles.integrationsContainer}>
                        <UqIntegrationTiles tiles={integrations.content} />
                      </section>
                    </Col>
                  </UqFlexRow>
                </div>
              </div>
            </UqContainer>
          </UqContainer>
        </section>
        <section className={styles.qualityCommunity}>
          <UqContainer>
            <div className={styles.titleContainer}>
              <h2 className={styles.qualityCommunityTitle}>
                <UqTextHighlight>
                  {qualityCommunity.title}
                </UqTextHighlight>
              </h2>
              <div className={classNames('body-1', styles.qualityCommunityDescription)}>
                {qualityCommunity.description.description}
              </div>
              <Button href={qualityCommunity.actions[0].href}>
                {qualityCommunity.actions[0].label}
              </Button>
            </div>
          </UqContainer>

          <UqContainerV2
            overflowX
            fill
          >
            <div className={styles.customerImageContainer}>
              <Image
                className={styles.customerImage}
                src="/images/v2/agentq/community.png"
              />
            </div>
          </UqContainerV2>
        </section>
        <UqContainer>
          <UqRequestDemoBanner />
        </UqContainer>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );
}

/*  @todo [agentQ] Replace name in queries */
export const pageQuery = graphql`
  query {
    hero: contentfulHero(pageId: {eq: "gpt"}) {
      media {
        url
      }
      pageId
      subtitle
      title
    }
    integrations: contentfulContentGroup(slug: {eq: "unitq-gpt-integrations"}) {
      content {
        ... on ContentfulIntegration {
          logo {
            svg {
              content
            }
          }
          name
        }
      }
      subtitle
      title
    }
    qualityCommunity: contentfulFigureRow(pageId: {eq: "*-meet-the-quality-community"}) {
      actions {
        href
        label
        type
      }
      description {
        description
      }
      image {
        url
      }
      title
    }
    quickActions: contentfulContentGroup(slug: {eq: "unitq-gpt-quick-action-tiles"}) {
      content {
        ... on ContentfulTile {
          action {
            href
            label
            metadata {
              variant
            }
            type
          }
          desc {
            desc
          }
          image {
            url
          }
          title
        }
      }
    }
    seo: contentfulSeo(pageId: {eq: "gpt"}) {
      description
      image {
        url
      }
      title
    }
    testimonials: contentfulContentGroup(slug: {eq: "unitq-gpt-testimonials"}) {
      content {
        ... on ContentfulTestimonial {
          author {
            image {
              url
            }
            name
            role
          }
          customer {
             logo {
              svg {
                content: originalContent
              }
            }
          }
          quote {
            quote
          }
        }
      }
      subtitle
      title
    }
    valueProps: contentfulContentGroup(slug: {eq: "gpt-value-props"}) {
      content {
        ... on ContentfulTile {
          desc {
            desc
          }
          icon {
            svg {
              content: originalContent
            }
          }
          title
        }
      }
    }
    whyAgentq: contentfulFigureRow(pageId: {eq: "gpt"}) {
      description {
        description
      }
      textAlign
      title
    }
  }
`;
